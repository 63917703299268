import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Label from '../ui/Label';
import { authenticateUser } from '../../action/auth';
import {LoginOutlined } from '@ant-design/icons'

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Login / Medtalks Academy Admin";
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!password) {
      newErrors.password = 'Password is required';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setErrors({});

    const data = { email, password };

    try {
      const response = await authenticateUser(data);
      console.log(response);
      if (response === "Authentication failed") {
        setErrors({ auth: 'Invalid credentials' });
      } else {
        navigate('/courses');
      }
    } catch (err) {
      setErrors({ auth: 'An error occurred. Please try again later.' });
      console.error('Login error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setEmail('');
    setPassword('');
    setErrors({});
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#FCFCFC]">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96 border-2 ">
        <div className="bg-white w-12 h-12 rounded-lg shadow-sm border-2 border-gray-100 mx-auto">
          <div className='m-2.5 text-gray-700'>
          <LoginOutlined style={{ fontSize: '25px' }} type="setting" />
          </div>
        </div>
        <div className='text-center'>
          <h2 className="text-xl font-semibold font-sanstext-gray-800 mt-2">Log in to your account</h2>
          <h3 className="text-sm font-thin font-sans text-gray-800 my-1 mb-3"> Welcome back! Please enter your details.</h3>
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <Label htmlFor="email">Email</Label>
            <div className="flex items-center border rounded-md bg-gray-50">
              <input
                type="email"
                id="email"
                className="w-full px-4 py-2 text-gray-700 bg-transparent border-none focus:outline-none"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <Label htmlFor="password">Password</Label>
            <div className="flex items-center border rounded-md bg-gray-50">
              <input
                type="password"
                id="password"
                className="w-full px-4 py-2 text-gray-700 bg-transparent border-none focus:outline-none"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
          </div>
          {errors.auth && <div className="text-red-500 mb-4">{errors.auth}</div>}
          <button
            type="submit"
            className="w-full py-2 px-4 bg-[#027253] hover:bg-[#1f6451] text-white rounded-md shadow mb-2"
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
          <button
            type="button"
            className="w-full py-2 px-4 text-black border border-gray-300 bg-white rounded-md shadow"
            onClick={handleReset}
          >
            Reset
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
