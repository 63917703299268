import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, Form, Switch,Popconfirm } from 'antd';
import { Check, X, Trash2,Plus } from 'lucide-react';
import Card, { CardContent, CardHeader, CardTitle } from './../ui/cards';
import { post } from '../../services/fetch';
import { useCourse } from '../../context/coursecontext';
import { useLocation,useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import {message} from 'antd'
const MCQComponent = () => {
  const [mcqs, setMcqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { lectureId, setLectureId } = useCourse();
  const searchParams = new URLSearchParams(location.search);
  const lectureIdFromQuery = searchParams.get('lecture_id');
  const [question, setQuestion] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [answers, setAnswers] = useState([]);
  const [answerOrder, setAnswerOrder] = useState('');
  const [answerInput, setAnswerInput] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [editing, setEditing] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentSortOrder, setCurrentSortOrder] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title ='Add MCQ | Medtalks Academy Admin';
  },[])
  useEffect(() => {
    // Check if the '_GACOOKIE' token exists
    const token = Cookies.get('_GACOOKIE_ADMIN');
    if (!token) {
      // If token does not exist, redirect to the homepage
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (lectureIdFromQuery) {
      setLectureId(lectureIdFromQuery);
      fetchQuestions();
    }
  }, [lectureIdFromQuery, setLectureId]);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await post('get-question', { lecture_id: lectureIdFromQuery }, true, true);
      if (response && response.code == 200) {
        if (Array.isArray(response.data)) {
          // Ensure each answer includes the answer_id
          const updatedMcqs = response.data.map(mcq => ({
            ...mcq,
            answers: mcq.answers.map(answer => ({
              ...answer,
              answer_id: answer.id // Save answer id for future use
            }))
          }));
          setMcqs(updatedMcqs);
        } else {
          console.error('Unexpected response format:', response.data);
          setError('Unexpected response format');
        }
      } else {
        console.error('Failed to fetch questions');
      }
    } catch (err) {
      console.error('Error fetching questions:', err);
      setError('An error occurred while fetching questions');
    } finally {
      setLoading(false);
    }
  };
  
  const addAnswer = () => {
    if (answerInput && answerOrder) {
      setAnswers([
        ...answers,
        { answer_title: answerInput, answer_order: parseInt(answerOrder, 10), is_correct: 0 } // Set is_correct to 0 by default
      ]);
      setAnswerInput('');
      setAnswerOrder('');
      setIsModalVisible(false);
    }
  };

  const toggleCorrectAnswer = (answerIndex) => {
    const updatedAnswers = answers.map((answer, index) => ({
      ...answer,
      is_correct: index === answerIndex ? 1 : 0 // Set the selected answer as 1, others as 0
    }));
    setAnswers(updatedAnswers);
  };

  const removeAnswer = (answerIndex) => {
    const updatedAnswers = answers.filter((_, index) => index !== answerIndex);
    setAnswers(updatedAnswers);
  };

  const addQuestion = async () => {
    if (question && sortOrder && answers.length > 0) {
      // Check if there is at least one correct answer
      const hasCorrectAnswer = answers.some(answer => answer.is_correct === 1);
      if (!hasCorrectAnswer) {
        message.error('Please select at least one correct answer.');
        return;
      }
  
      try {
        const payload = {
          question_title: question,
          question_order: parseInt(sortOrder, 10),
          lecture_id: parseInt(lectureIdFromQuery, 10),
          answers: answers,
        };
  
        const response = await post('add-question', payload, true, true);
  
        if (response && response.code === 200) {
          console.log('Question saved successfully:', response.data);
          setQuestion('');
          setSortOrder('');
          setAnswers([]);
          fetchQuestions(); // Fetch questions after successful addition
        } else {
          console.error('Failed to save question:', response.statusText);
        }
      } catch (error) {
        console.error('Error saving question:', error);
      }
    }
  };
  

  const startEditing = (mcq) => {
    setEditing(mcq.id);
    setCurrentQuestion(mcq.question_title);
    setCurrentSortOrder(mcq.question_order);
    setCurrentAnswers(mcq.answers);
  };
  const handleAnswerChange = (index, field, value) => {
    setCurrentAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers];
      if (field === 'is_correct') {
        // Set only the selected answer as correct, others as incorrect
        updatedAnswers.forEach((answer, i) => {
          answer.is_correct = i === index ? 1 : 0;
        });
      } else {
        updatedAnswers[index] = { ...updatedAnswers[index], [field]: value };
      }
      return updatedAnswers;
    });
  };
  
  const handleQuestionUpdate = async (id) => {
    if (currentQuestion && currentSortOrder && currentAnswers.length > 0) {
      const hasCorrectAnswer = currentAnswers.some(answer => answer.is_correct === 1);
      if (!hasCorrectAnswer) {
        message.error('Please select at least one correct answer.');
        return;
      }
  
      try {
        const payload = {
          id: id,
          question_title: currentQuestion,
          question_order: parseInt(currentSortOrder, 10),
          lecture_id: parseInt(lectureIdFromQuery, 10),
          answers: currentAnswers.map(answer => ({
            id: answer.answer_id,
            answer_title: answer.answer_title,
            is_correct: answer.is_correct,
            answer_order: parseInt(answer.answer_order, 10),
          })),
        };
  
        const response = await post('update-question', payload, true, true);
  
        if (response && response.code === 200) {
          console.log('Question updated successfully:', response.data);
          setEditing(null); // Exit edit mode
          fetchQuestions(); // Refresh the list of questions after successful update
        } else {
          console.error('Failed to update question:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating question:', error);
      }
    }
  };
  
  
  
 
  const handleAnswerRemove = async (answerId) => {
    if (answerId) {
      // If answerId exists, proceed with API call to delete the answer
      const payload = { id: answerId, type: 'answer' };
  
      try {
        const response = await post('delete-content', payload, true, true);
        if (response && response.code === 200) {
          // Remove from state if the API call was successful
          setCurrentAnswers(prevAnswers => prevAnswers.filter(answer => answer.answer_id !== answerId));
          console.log('Answer deleted successfully:', response.data);
        } else {
          console.error('Failed to delete answer:', response.statusText, response);
        }
      } catch (error) {
        console.error('Error deleting answer:', error);
      }
    } else {
      // If answerId does not exist (newly added answers), remove it from local state
      setCurrentAnswers(prevAnswers => prevAnswers.filter(answer => answer.answer_id));
      console.log('New answer removed successfully.');
    }
  };
  

  const handleAddAnswer = () => {
    setCurrentAnswers(prevAnswers => [
      ...prevAnswers,
      { answer_title: '', answer_order: prevAnswers.length + 1, is_correct: false }
    ]);
  };

  const handleQuestionRemove = async (id) => {
    if (id) {
      const payload = { id: id, type: 'question' };
      const response = await post('delete-content', payload, true, true);
      if (response && response.code === 200) {
        setMcqs(mcqs.filter(mcq => mcq.id !== id));
      } else {
        setError('Failed to delete question');
      }
    }
  };
  

  return (
    <div className="min-h-screen p-4 sm:p-6">
      <div className="max-w-9xl mx-auto">
        <h1 className='text-2xl justify-start font-sans font-semibold  my-3 text-black'>Add MCQs</h1>
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-gray-800">MCQ Form</CardTitle>
          </CardHeader>
          <CardContent>
          <Form layout="vertical" onFinish={addQuestion} className="space-y-4">
  <div className="text-lg font-medium text-gray-700 mb-2">Add a new question:</div>
  <Form.Item label="Question" required>
    <Input
      placeholder="Enter question"
      value={question}
      onChange={(e) => setQuestion(e.target.value)}
      className="w-full rounded-md border-gray-300 shadow-sm px-3 py-3 focus:border-green-300 focus:ring focus:ring-[#247F4D] focus:ring-opacity-50"
    />
  </Form.Item>

  <Form.Item label="Sort Order" required>
    <Input
      type="number"
      placeholder="Enter sort order"
      value={sortOrder}
      onChange={(e) => setSortOrder(e.target.value)}
      className="w-full rounded-md border-gray-300 shadow-sm px-3 py-3 focus:border-green-300 focus:ring focus:ring-[#247F4D] focus:ring-opacity-50"
    />
  </Form.Item>

  {answers.length > 0 && (
    <div className="mb-4">
      <h4 className="text-lg font-semibold">Answers:</h4>
      <ul className="space-y-2">
        {answers.map((answer, index) => (
          <li
            key={index}
            className={`flex items-center justify-between p-2 rounded-md ${
              answer.is_correct ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
            }`}
          >
            <div className="flex items-center cursor-pointer flex-grow" onClick={() => toggleCorrectAnswer(index)}>
              {answer.is_correct ? (
                <Check className="w-4 h-4 mr-2 text-green-600" />
              ) : (
                <X className="w-4 h-4 mr-2 text-gray-400" />
              )}
              {answer.answer_title} - Order: {answer.answer_order}
            </div>
           <Popconfirm
        title="Are you sure to delete this answer?"
        onConfirm={() => removeAnswer(index)}
        okText="Yes"
        cancelText="No"
      >
        <Button icon={<Trash2 className="w-4 h-4" />} danger>
          Remove
        </Button>
      </Popconfirm>
          </li>
        ))}
      </ul>
    </div>
  )}

  <Button
    type="primary"
    onClick={() => setIsModalVisible(true)}
    className="custom-ant-btn bg-[#247F4D] hover:bg-green-900 border-green-600 hover:border-green-700 text-white font-medium py-4 px-4 rounded-md transition duration-150 ease-in-out sm:text-[16px] text-sm"
  >
    Add Answer
  </Button>

  <Form.Item className="mt-4">
    <div className="flex justify-end">
      <Button
        type="primary"
        htmlType="submit" // Remove onClick from this button
        className="custom-ant-btn bg-[#247F4D] hover:bg-green-900 border-green-600 hover:border-green-700 text-white font-medium py-5 px-5 rounded-md transition duration-150 ease-in-out sm:max-w-40 sm:text-[16px] text-sm"
      >
        Save Question
      </Button>
    </div>
  </Form.Item>
</Form>

          </CardContent>
        </Card>

        <Modal
          title="Add Answer"
          visible={isModalVisible}
          onOk={addAnswer}
          onCancel={() => setIsModalVisible(false)}
          okText="Add"
          cancelText="Cancel"
          okButtonProps={{
            className: 'bg-[#247F4D] hover:bg-green-900 border-green-600 hover:border-green-700',
          }}
        >
          <Form.Item label="Answer">
            <Input
              placeholder="Enter answer"
              value={answerInput}
              onChange={(e) => setAnswerInput(e.target.value)}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-[#247F4D] focus:ring-opacity-50"
            />
          </Form.Item>
          <Form.Item label="Order">
            <Input
              type="number"
              placeholder="Enter answer order"
              value={answerOrder}
              onChange={(e) => setAnswerOrder(e.target.value)}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-[#247F4D] focus:ring-opacity-50"
            />
          </Form.Item>
        </Modal>

        
        <div className="mt-6">
        <h2 className="text-xl font-bold mb-4 ml-8">List of Questions</h2>
        {loading ? (
          <p className="ml-8">Loading questions...</p>
        ) : error ? (
          <p className="text-red-500 ml-8">{error}</p>
        ) : mcqs.length === 0 ? (
          <p className="ml-8">No questions available.</p>
        ) : (
          <div className="grid grid-cols-1 gap-4 ml-8 mr-8">
            {mcqs.map((mcq) => (
              <Card key={mcq.id} className="w-full">
                <CardContent className="p-6">
                  {editing === mcq.id ? (
                    <Form layout="vertical" onFinish={() => handleQuestionUpdate(mcq.id)}>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Form.Item label="Question">
                          <Input
                            value={currentQuestion}
                            onChange={(e) => setCurrentQuestion(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item label="Sort Order">
                          <Input
                            type="number"
                            value={currentSortOrder}
                            onChange={(e) => setCurrentSortOrder(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      {currentAnswers.map((answer, index) => (
                        <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                          <Form.Item label={'Option'}>
                            <Input
                              value={answer.answer_title}
                              onChange={(e) => handleAnswerChange(index, 'answer_title', e.target.value)}
                            />
                          </Form.Item>
                          <Form.Item label="Option Order">
                            <Input
                              type="number"
                              value={answer.answer_order}
                              onChange={(e) => handleAnswerChange(index, 'answer_order', e.target.value)}
                            />
                          </Form.Item>
                          <Form.Item label="Correct">
                            <Switch
                              checked={answer.is_correct}
                              onChange={(checked) => handleAnswerChange(index, 'is_correct', checked)}
                            />
                          </Form.Item>
                          <Form.Item>
                         
                          <Popconfirm
  title="Are you sure to delete this answer?"
  onConfirm={() => handleAnswerRemove(answer.answer_id)}
  okText="Yes"
  cancelText="No"
>
  <Button icon={<Trash2 className="w-4 h-4" />} danger>
    Remove
  </Button>
</Popconfirm>


                          </Form.Item>
                        </div>
                      ))}
                      <Button
                        onClick={handleAddAnswer}
                        icon={<Plus className="w-4 h-4" />}
                        className="mb-4"
                      >
                        Add Answer
                      </Button>
                      <div className="flex justify-between items-center mt-4">
                        <Button onClick={() => setEditing(null)}>Cancel</Button>
                        <Button type="primary" htmlType="submit" className="bg-[#247F4D] hover:bg-green-700">Save Changes</Button>
                      </div>
                    </Form>
                  ) : (
                    <>
                      <h3 className="font-medium text-lg mb-2">{mcq.question_title}</h3>
                      <p className="text-sm text-gray-600">Question Order: {mcq.question_order}</p>
                      <ul className="space-y-2 mt-2">
  {mcq.answers.map((answer, index) => (
    <li key={answer.answer_id} className={`flex items-center justify-between p-2 rounded-md ${answer.is_correct ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
      <span>{answer.answer_title} (Order: {answer.answer_order})</span>
      {answer.is_correct === 1 && <Check className="w-4 h-4 ml-2 text-green-600" />}
    </li>
  ))}
</ul>

                      <div className="mt-4 flex justify-end space-x-2">
                        <Button
                          className="bg-[#247F4D] text-white hover:bg-green-700 transition duration-300"
                          onClick={() => startEditing(mcq)}
                        >
                          Edit
                        </Button>
                        <Popconfirm
  title="Are you sure you want to delete this question? This action cannot be undone."
  onConfirm={() => handleQuestionRemove(mcq.id)}
  okText="Yes, Delete It"
  cancelText="No"
>
  <Button danger>
    Remove Question
  </Button>
</Popconfirm>


                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>



      </div>
    </div>
  );
};

export default MCQComponent;
