import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Label from '../ui/Label';
import { Tabs } from 'antd';

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import EditorComponent from '../ui/JoditEditor';
import MultiSelectDropdown from '../ui/MultiSelectDropdown';
import SelectDropdown from '../ui/SelectDropdown';
import ToggleSwitch from '../ui/ToggleSwitch';
import { PlusOutlined } from '@ant-design/icons';
import {  Upload,message,Modal} from 'antd';
import { InputNumber, Switch, Button } from 'antd';
import MyComponent from '../envfetch';
import { post } from '../../services/fetch';
import { useCourse } from '../../context/coursecontext';
import { useLocation } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';

import Faq from './Faq'
import './style.css'



const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


  // Validate and handle file selection
  
 
const validateImageDimensions = (file, width, height) => {
  return new Promise((resolve) => {
    const img = new window.Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      if (img.width === width && img.height === height) {
        resolve(true);
      } else {
        message.error(`Image dimensions must be ${width}x${height} pixels.`);
        resolve(false);
      }
    };
  });
};

const Step1 = ({ onSubmit }) => {
  const { control, register, handleSubmit: handleSubmitCourseInfo, setValue, watch,setError, formState: { errors } } = useForm();
  const [imagePreview, setImagePreview] = useState(null);
  const [specialities, setSpecialities] = useState({});
  const [qualifications, setQualifications] = useState({});
  const [educationPartners, setEducationPartners] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [isToggledPaid, setIsToggledPaid] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileVideoThumbnail, setFileVideoThumbnail] = useState([]);
  const [priceInputs, setPriceInputs] = useState([]);
  const [customUrl, setCustomUrl] = useState('');
  const [videoThumbnailBase64, setVideoThumbnailBase64] = useState('');
  const [fileSquareImage, setFileSquareImage] = useState([]);
  const [countryList, setCountryList] = useState([]); // Updated state for storing country list
  const [fileBrochure, setFileBrochure] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [squareImageBase64, setSquareImageBase64] = useState('');
  const [currency, setCurrency] = useState('');
  const { setCourseId } = useCourse(); // Get the setter function for courseId
  const location = useLocation();
  const [fileCertificate, setFileCertificate] = useState([]);
  const [certificateBase64, setCertificateBase64] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [courseData, setCourseData] = useState(null);
  const courseInfoForm = useForm();
  const [activeTabKey, setActiveTabKey] = useState('1');

  const [imageErrors, setImageErrors] = useState({
    squareImage: '',
    courseCertificate: ''
  });
const [brochureBase64, setBrochureBase64] = useState('');

const [startDate, endDate] = watch(['start_date', 'end_date']);

  // Custom validation for date fields
  useEffect(() => {
    if (startDate) {
      const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

      

      if (endDate && endDate <= startDate) {
        setError('end_date', {
          type: 'manual',
          message: 'End date must be after the start date',
        });
      } else {
        setError('end_date', {});
      }
    }
  }, [startDate, endDate, setError]);



  const handleBrochureUpload = async (event) => {
    const file = event.target.files[0];
  
    // If no file is selected
    if (!file) {
      setBrochureBase64('');
      setFileBrochure(null);
      setValue('course_brochure', '');
      message.info('No file chosen');
      return;
    }
  
    // Check if file is a PDF
    if (file.type !== 'application/pdf') {
      message.error('Please upload a valid PDF file');
      setBrochureBase64('');
      setFileBrochure(null);
      setValue('course_brochure', ''); // Reset form value
      return;
    }
  
    // Check file size (1 MB = 1024 * 1024 bytes)
    const fileSizeInMB = file.size / 1024 / 1024; // Size in MB
    if (fileSizeInMB > 1) {
      // If file exceeds 1 MB, show error message and reset
      message.error('File size should not exceed 1 MB');
      setBrochureBase64('');
      setFileBrochure(null);
      setValue('course_brochure', ''); // Reset form value
      return;
    }
  
    // If file is valid and within size limit, convert to base64 and update states
    const base64 = await getBase64(file);
    setBrochureBase64(base64);
    setFileBrochure(file);
    setValue('course_brochure', base64); // Set base64 value in the form
    message.success('File uploaded successfully');
  };
  
  
  const validateTabOne = () => {
    return activeTabKey === '1'; // Only validate Tab 1 fields if it's active
  };

  const validateTabTwo = () => {
    return activeTabKey === '2'; // Only validate Tab 2 fields if it's active
  };
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the '_GACOOKIE' token exists
    const token = Cookies.get('_GACOOKIE_ADMIN');
    if (!token) {
      // If token does not exist, redirect to the homepage
      navigate('/');
    }
  }, [navigate]);
  useEffect(() => {
    getSpecialities();
    getQualifications();
    getEducationPartners();
    getCountry();
  }, []);
 
    const queryParams = new URLSearchParams(location.search);
  const courseId = queryParams.get('id');

  const courseName = watch('course_name');

  useEffect(() => {
    if (courseName) {
      const customUrl = courseName.toLowerCase().replace(/\s+/g, '-');
      setValue('custom_url', customUrl);
    }
  }, [courseName, setValue]);

  useEffect(() => {
    if (courseId) {
      fetchCourseDetails();
    } else {
      setIsLoading(false);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      document.title = "Edit Course / Medtalks Academy Admin"; 
    } else {
      document.title = "Add Course / Medtalks Academy Admin"; 
    }
  }, [courseId]);
  
  const fetchCourseDetails = async () => {
    setIsLoading(true);
    try {
      const response = await post('get-courses', { course_id: courseId }, true, true);
      if (response.code === 200) {
        const course = response.data.data[0];
        setCourseData(course);
  
        // Prefill form data including images
        prefillFormData(course);
  
        // Set images as Base64 if the server expects Base64, else just use the URL
        setSquareImageBase64(course.square_image);
        setVideoThumbnailBase64(course.video_thumbnail);
        setCertificateBase64(course.course_certificate);
      setFileBrochure(course.course_brochure);

        
      } else {
        console.error('Error fetching course details:', response.message);
      }
    } catch (error) {
      console.error('Error fetching course details:', error);
    } finally {
      setIsLoading(false);
    }
  };

 
  
  const { TabPane } = Tabs;

 

  const prefillFormData = (course) => {
    console.log('Prefilling form data:', course);
  
    // Basic fields
    setValue('course_name', course.course_name || '');
    setValue('custom_url', course.custom_url || '');
    setValue('short_desc', course.short_desc || '');
    setValue('detailed_desc', course.detailed_desc || '');
    setValue('preview_video', course.preview_video || '');
    setValue('start_date', course.start_date || '');
    setValue('end_date', course.end_date || '');
    setValue('objectives', course.objectives || ''); // Prefill the objectives field

    // Toggle switches
    setIsToggledPaid(course.is_paid_course === 1);
    setIsToggled(course.is_external === 1);
  setIsPrimary(course.is_primary === 1);

    // Select fields
    setValue('course_type', optionsCoursetype.find(item => item.value === course.course_type) || null);
    setValue('status', courseStatus.find(item => item.value === course.status) || null);
  
    // Multi-select fields
    const prefillMultiSelect = (fieldName, data, valueField = 'id', labelField = 'name') => {
      if (data && Array.isArray(data)) {
        const values = data.map(item => ({
          value: item[valueField],
          label: item[labelField] ? item[labelField].toString() : item[valueField].toString()
        }));
        console.log(`Setting ${fieldName}:`, values);
        setValue(fieldName, values);
      }
    };
    
    prefillMultiSelect('education_partners', course.education_partners, 'id', 'sponsor_name');
    prefillMultiSelect('qualification', course.qualification, 'id', 'name');
    prefillMultiSelect('specialities', course.specialities, 'id', 'title');
    
    // For country_id (assuming it's an array of IDs)
    if (course.countries && course.countries.length > 0) {
      const countryDetails = course.countries.map((country) => ({
        value: country.id,
        label: country.title,
        currency: course.price?.currency,
        currency_icon: course.price?.currency_icon
      }));
      console.log('Setting country_id:', countryDetails);
      setValue('country_id', countryDetails);
    }
  
    console.log('Is paid course:', course.is_paid_course);
  console.log('Price data:', course.price);
  console.log('Is price an array:', Array.isArray(course.price));
// Check if price inputs have already been set to avoid looping
if (priceInputs.length === 0 && course.is_paid_course === 1) {
  const coursePrices = Array.isArray(course.price) ? course.price : [course.price];
  const newPriceInputs = coursePrices.map((price, index) => {
      const countryData = {
          value: price.id,
          label: price.name,
          currency: price.currency,
          currencyIcon: price.currency_icon,
      };

      // Set form values using react-hook-form's setValue
      setValue(`prices[${index}].country`, countryData);
      setValue(`prices[${index}].currencySymbol`, price.currency_icon);
      setValue(`prices[${index}].price`, parseFloat(price.amount));
      setValue(`prices[${index}].is_primary`, price.is_primary === 1); // Ensuring is_primary is set

      // Return a new price input object, including is_primary
      return { 
          id: index, 
          price_id: price.price_id,  
          country: countryData, 
          price: parseFloat(price.amount), 
          currencySymbol: price.currency_icon,
          is_primary: price.is_primary === 1 // Ensure this matches the API data type
      };
  });
  setPriceInputs(newPriceInputs);
}

    // Images - Use URLs for preview
    if (course.square_image_path) {
      setFileSquareImage([
        {
          uid: '-1',
          name: 'Square Image',
          status: 'done',
          url: course.square_image_path,
        },
      ]);
    }
  
    if (course.video_thumbnail_path) {
      setFileVideoThumbnail([
        {
          uid: '-2',
          name: 'Video Thumbnail',
          status: 'done',
          url: course.video_thumbnail_path,
        },
      ]);
    }
  
    // Prefill course_certificate_path
    if (course.course_certificate_path) {
      setFileCertificate([
        {
          uid: '-3',
          name: 'Course Certificate',
          status: 'done',
          url: course.course_certificate_path,
        },
      ]);
    }

   if (course.course_brochure_path) {
    setValue('course_brochure', course.course_brochure_path);
    setFileBrochure([{ uid: '-1', name: 'Brochure.pdf', status: 'done', url: course.course_brochure_path }]);
  } else {
    setFileBrochure([]);
  }

    
    console.log('Form prefill completed');
  
  


setCourseId(course.id);

  }

  const togglePrimary = (index) => {
    setPriceInputs(prevInputs =>
      prevInputs.map((item, idx) => ({
        ...item,
        is_primary: idx === index
      }))
    );
  
  };  
  useEffect(() => {
    priceInputs.forEach((input, index) => {
      setValue(`prices[${index}].is_primary`, input.is_primary);
    });
  }, [priceInputs, setValue]);
  
  
  const PriceInput = ({ control, index, onRemove, countryList, isPrimary, togglePrimary }) => {
    const selectedInput = priceInputs[index] || {};
    const [selectedCountry, setSelectedCountry] = useState(selectedInput.country || null);
    const [currencySymbol, setCurrencySymbol] = useState(selectedInput.currencySymbol || '$'); // Set initial value based on input

    const handlePriceBlur = (value) => {
      setPriceInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs[index] = {
          ...updatedInputs[index],
          price: parseFloat(value) || '', // Set final value as a number or empty if cleared
        };
        return updatedInputs;
      });
    };
    const handleCountryChange = (selected) => {
      setSelectedCountry(selected);
      const newSymbol = selected?.currencyIcon || '$';
      setCurrencySymbol(newSymbol);
  
      // Update the currency symbol in the main state to persist changes
      setPriceInputs(prevInputs => {
        const updatedInputs = [...prevInputs];
        updatedInputs[index] = { ...updatedInputs[index], country: selected, currencySymbol: newSymbol };
        return updatedInputs;
      });
    };
  
    const renderCurrencyIcon = (icon) => {
      if (icon.startsWith('<svg')) {
        // If it's an SVG, render it with adjusted size
        return (
          <span 
            dangerouslySetInnerHTML={{ 
              __html: icon.replace('<svg', '<svg width="16" height="16"') 
            }} 
          />
        );
      }
      // If it's not an SVG, just return the icon as is
      return icon;
    };
   
   
  
    return (
      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-2">
        <Controller
          name={`prices[${index}].country`}
          control={control}
          rules={{ required: 'Country is required' }}
          render={({ field }) => (
            <Select
              {...field}
              options={countryList}
              value={selectedCountry}
              onChange={(selected) => {
                field.onChange(selected);
                handleCountryChange(selected); // Update currency symbol independently
              }}
              placeholder="Select country"
              className="w-full sm:w-1/3"
            />
          )}
        />
        <div className="relative flex-1 w-full">
        <Controller
          name={`prices[${index}].price`}
          control={control}
          defaultValue={selectedInput.price || ""}
          render={({ field }) => (
            <div className="flex items-center border border-gray-300 rounded-md">
              <span className="pl-3 pr-1">
                {renderCurrencyIcon(currencySymbol)}
              </span>
              <input
              {...field}
              type="number"
              placeholder="Enter price"
              className="w-full pl-1 pr-3 py-2 border-0 focus:ring-0"
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value); // Update Controller field value directly
              }}
              onBlur={(e) => handlePriceBlur(e.target.value)} // Final update to main state on blur
              value={field.value || ''} // Bind field.value to ensure controlled behavior
            />
            </div>
          )}
        />

        </div>
        <Switch
        checked={isPrimary}
        onChange={() => togglePrimary(index)}
        checkedChildren="Primary"
        unCheckedChildren="Set Primary"
      />



        <button
          type="button"
          onClick={() => onRemove(index)}
          className="text-red-500 w-full sm:w-auto mt-2 sm:mt-0"
        >
          Remove
        </button>
      </div>
    );
  };
  
  
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url?.substring(file.url.lastIndexOf('/') + 1));
  };
  
  const handleChangeImage = async ({ fileList: newFileList }) => {
    const file = newFileList[0]?.originFileObj;
    if (file) {
      const isValid = await validateImageDimensions(file, 770, 550);
      if (isValid) {
        setFileVideoThumbnail(newFileList);
        const base64 = await getBase64(file);
        setVideoThumbnailBase64(base64);
        setValue('video_thumbnail', base64);
      } else {
        setFileVideoThumbnail([]);
        setVideoThumbnailBase64('');
        setValue('video_thumbnail', '');
      }
    } else {
      setFileVideoThumbnail([]);
      setVideoThumbnailBase64('');
      setValue('video_thumbnail', '');
    }
  };

  const handleChangeSquareImage = async ({ fileList: newFileList }) => {
    const file = newFileList[0]?.originFileObj;
    if (file) {
      const isValid = await validateImageDimensions(file, 600, 600);
      if (isValid) {
        setFileSquareImage(newFileList);
        const base64 = await getBase64(file);
        setSquareImageBase64(base64);
        setValue('square_image', base64);
      } else {
        setFileSquareImage([]);
        setSquareImageBase64('');
        setValue('square_image', '');
      }
    } else {
      setFileSquareImage([]);
      setSquareImageBase64('');
      setValue('square_image', '');

    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleToggleChange = (event) => {
    setIsToggled(event.target.checked);
  };

  const handleTogglePaidChange = (event) => {
    setIsToggledPaid(event.target.checked);
    if (!event.target.checked) {
      setPriceInputs([]);
    }
  };
  
  
  const handleChangeCertificate = async ({ fileList: newFileList }) => {
    const file = newFileList[0]?.originFileObj;
    if (file) {
      if (file.size > 1024 * 1024) { // 1 MB limit
        message.error('Certificate file size must be less than 1 MB.');
        setFileCertificate([]);
        setCertificateBase64('');
        setValue('course_certificate', '');
        return;
      }
      const isValid = await validateImageDimensions(file, 1600, 1165);
      if (isValid) {
        setFileCertificate(newFileList);
        const base64 = await getBase64(file);
        setCertificateBase64(base64);
        setValue('course_certificate', base64);
      } else {
        setFileCertificate([]);
        setCertificateBase64('');
        setValue('course_certificate', '');
      }
    } else {
      setFileCertificate([]);
      setCertificateBase64('');
      setValue('course_certificate', '');
    }
  };



  const onSubmitCourseInfo = async (data) => {
    setImageErrors({
        squareImage: '',
        courseCertificate: ''
    });

    if (!squareImageBase64 && !fileSquareImage.length && !courseId) {
        setImageErrors(prev => ({ ...prev, squareImage: 'Please upload the Square Image.' }));
        message.error('Please upload the Square Image.');
        return;
    }

    if (!certificateBase64 && !fileCertificate.length && !courseId) {
        setImageErrors(prev => ({ ...prev, courseCertificate: 'Please upload the Course Certificate.' }));
        message.error('Please upload the Course Certificate.');
        return;
    }
    if (isToggledPaid) {
      // Ensure at least one price is present
      if (priceInputs.length === 0) {
        message.error('Please add at least one price for a paid course.');
        return;
      }
  
      // Ensure exactly one is_primary is selected
      const primaryCount = priceInputs.filter((input) => input.is_primary).length;
      if (primaryCount !== 1) {
        message.error('Please select exactly one primary price.');
        return;
      }
  
      // Validate price values
      const invalidPrices = priceInputs.filter((input) => !input.price || input.price <= 0);
      if (invalidPrices.length > 0) {
        message.error('Please ensure all prices are valid and greater than 0.');
        return;
      }
    }
  

    // Ensure required fields are present in the payload
    const modifiedData = {
        course_name: data.course_name || '',
        detailed_desc: data.detailed_desc || '',
        short_desc: data.short_desc || '',
        start_date: data.start_date || '',
        end_date: data.end_date || '',
        preview_video: data.preview_video,
        custom_url: data.custom_url || '',
        is_external: isToggled ? '1' : '0',
        is_paid_course: isToggledPaid ? '1' : '0',
        course_type: data.course_type?.value,
        status: data.status?.value,
        country_id: data.country_id ? data.country_id.map(item => item.value) : [],
        specialities: data.specialities ? data.specialities.map(item => item.value) : [],
        education_partners: data.education_partners ? data.education_partners.map(item => item.value) : [],
        qualification: data.qualification ? data.qualification.map(item => item.value) : [],
        square_image: squareImageBase64 || '',
        video_thumbnail: videoThumbnailBase64 || '',
        course_certificate: certificateBase64 || '',
        objectives: data.objectives || '',
        course_brochure: brochureBase64,
        paid: priceInputs.map(input => ({
            id: input.price_id, 
            country_id: input.country?.value || null,
            price: input.price.toString(),
            is_primary: input.is_primary ? 1 : 0,
        })),
    };
    console.log("Payload Data Before API Request:", modifiedData);

    try {
        let res;
        if (courseId) {
            res = await post('update-course', { course_id: courseId, ...modifiedData }, true, true);
        } else {
            res = await post('add-course', modifiedData, true, true);
        }

        if (res && res.data && res.data.id) {
            setCourseId(res.data.id);
            message.success('Course successfully saved!');
            onSubmit(modifiedData); // Continue to submit or navigate if success
        } else if (res.code === 400 && res.data) {
            res.data.forEach(error => {
                message.error(error.message);
                setError(error.field_name, {
                    type: "manual",
                    message: error.message,
                });
            });
            message.warning('Please correct the highlighted errors and try again.');
        } else {
            message.error('Failed to save course. Please try again.');
        }
    } catch (e) {
        console.error('Error posting to API:', e);
        message.error('An error occurred while saving the course.');
    }
};

  // const getCourseFaqs = async (courseId) => {
  //   if (!courseId) {
  //     message.error('Cannot retrieve FAQs without a course ID');
  //     return;
  //   }
  
  //   try {
  //     // Call the get-course-faqs API
  //     const response = await post('get-course-faqs', { course_id: courseId }, true, true);
  
  //     // Check if the FAQs were retrieved successfully
  //     if (response.code === 200 && response.data?.faqs) {
  //       // Successfully retrieved FAQs
  //       const faqs = response.data.faqs;
  
  //       // Process the FAQs as needed (e.g., display them in the UI)
  //       return faqs;
  //     } else {
  //       message.error('Failed to retrieve FAQs');
  //     }
  //   } catch (error) {
  //     console.error('Error retrieving FAQs:', error);
  //     message.error('An error occurred while retrieving FAQs');
  //   }
  // };
  
  
  const onSubmitFAQ = async (data) => {
    if (!courseId) {
      message.error('Cannot submit FAQ without a course ID');
      return;
    }
  
    try {
      // Call the add-faq API
      const response = await post('add-course-faqs', { course_id: courseId, faqs: data.faq }, true, true);
  
      // Check if the FAQ was added successfully
      if (response.code === 200 ) {
        message.success('FAQ added successfully');
      } else {
        message.error('Failed to add FAQ');
      }
    } catch (error) {
      console.error('Error adding FAQ:', error);
      message.error('An error occurred while adding FAQ');
    }
  };
  
  
  
  const addPriceInput = () => {
    setPriceInputs(prevInputs => [
      ...prevInputs,
      { id: prevInputs.length, country: null, price: '',is_primary: false  } // Add a new input with an initial empty state
    ]);
  };
  
  const removePriceInput = async (index) => {
    const priceToRemove = priceInputs[index];
    if (priceToRemove && priceToRemove.price_id) { // Ensure price_id is available
      try {
        const response = await post('delete-content', {
          type: 'price',
          id: priceToRemove.price_id, // Using price_id here
        }, true, true);
  
        if (response.code === 200) {
          message.success('Price removed successfully');
          setPriceInputs(priceInputs.filter((_, i) => i !== index));
        } else {
          message.error('Failed to remove price');
        }
      } catch (error) {
        console.error('Error removing price:', error);
        message.error('An error occurred while removing the price');
      }
    } else {
      setPriceInputs(priceInputs.filter((_, i) => i !== index));
    }
  };
  
  

  const getSpecialities = async () => {
    try {
      const response = await post('get-speciality', true, true);
      if (response.code === 200) {
        const specialities = response.data.map(item => ({
          value: item.id,
          label: item.title
        }));
        setSpecialities(specialities);
      } else {
        setSpecialities([]);
      }
    } catch (error) {
      console.error('Error fetching specialities:', error);
      setSpecialities([]);
    }
  };

  const getQualifications = async () => {
    try {
      const response = await post('get-qualifications', true, true);
      if (response.code === 200) {
        const qualifications = response.data.map(item => ({
          value: item.id,
          label: item.name
        }));
        setQualifications(qualifications);
      } else {
        setQualifications([]);
      }
    } catch (error) {
      console.error('Error fetching qualifications:', error);
      setQualifications([]);
    }
  };

  const getEducationPartners = async () => {
    try {
      const response = await post('get-education-partners', true, true);
      if (response.code === 200) {
        const educationPartners = response.data.map(item => ({
          value: item.id,
          label: item.sponsor_name
        }));
        setEducationPartners(educationPartners);
      } else {
        setEducationPartners([]);
      }
    } catch (error) {
      console.error('Error fetching education partners:', error);
      setEducationPartners([]);
    }
  };

  const getCountry = async () => {
    try {
      const response = await post('get-country', {}, true, true); // Assuming no body is required
      if (response.code === 200) {
        const countries = response.data.map(item => ({
          value: item.id,
          label: item.name,
          currency: item.currency,
          currencyIcon: item.currency_icon
        }));
        setCountryList(countries); // Updated the state with the fetched country data
      } else {
        setCountryList([]); // Reset the list if the API call fails
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setCountryList([]);
    }
  };

  // Fetch country data when the component is mounted
  useEffect(() => {
    getCountry();
    // Other API calls for specialities, qualifications, etc.
  }, []);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    // Check if the FAQ tab is activated and if a course ID is available
    // if (key === '2' && courseId) {
    //   getCourseFaqs(courseId).then(faqs => {
    //     // Handle the FAQs data, e.g., setting it in state or passing to the FAQ component
    //     console.log(faqs); // Log or set state here
    //   }).catch(error => {
    //     console.error('Failed to fetch FAQs:', error);
    //     message.error('Failed to load FAQs');
    //   });
    // }
  };

 

  
  const optionsCoursetype = [
    { value: 'certification', label: 'Certification' },
    { value: 'fellowship', label: 'Fellowship' }
  ];

  const courseStatus = [
    { value: 'live', label: 'Live' },
    { value: 'schedule', label: 'Schedule' },
    { value: 'draft', label: 'Draft' },
    { value: 'archive', label: 'Archive' }
  ];


  return (
    <div className="bg-white rounded-lg p-4 md:p-6">
      <Tabs activeKey={activeTabKey} onChange={onTabChange}>
        <Tabs.TabPane tab="Course Information" key="1">

      <h2 className="text-xl font-semibold mb-4 md:mb-6">Course Information</h2>
      <form onSubmit={handleSubmitCourseInfo(onSubmitCourseInfo)}>
        <div className="space-y-4">
          <div className="flex flex-row gap-4">
            <div className="w-auto">
              <ToggleSwitch
                checked={isToggled}
                onChange={handleToggleChange}
                label="Is External"
              />
            </div>
            <div className="w-auto">
              <MyComponent />
            </div>
            <div className="w-auto">
              <ToggleSwitch
                checked={isToggledPaid}
                onChange={handleTogglePaidChange}
                label="Is Paid"
              />
            </div>
          </div>

          <div>
            <Label htmlFor="course_name">Course Title</Label>
            <input
              type="text"
              {...register('course_name', { required: 'Course title is required' })}
              className="w-full px-4 py-2 border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
              placeholder="Enter course title"
            />
            {errors.course_name && <p className="text-red-500 text-sm mt-1">{errors.course_name.message}</p>}
          </div>

          <div>
            <Label htmlFor="custom_url">Custom URL Title</Label>
            <input
              type="text"
              {...register('custom_url', { required: 'Custom URL is required' })}
              className="w-full px-4 py-2 border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
              placeholder="Enter custom URL"
            />
            {errors.custom_url && <p className="text-red-500 text-sm mt-1">{errors.custom_url.message}</p>}
          </div>

          <div>
  <Label htmlFor="short_desc">Short Desc (Max 350 characters)</Label>
  <EditorComponent
    name="short_desc"
    control={control}
    rules={{
      required: 'Short Description is required',
      maxLength: {
        value: 350,
        message: 'Short Description cannot exceed 350 characters',
      },
    }}
  />
  {errors.short_desc && <p className="text-red-500 text-sm mt-1">{errors.short_desc.message}</p>}
</div>


          <div>
  <Label htmlFor="objectives">Course Objectives</Label> {/* New Objectives field */}
  <EditorComponent
    name="objectives"
    control={control}
    rules={{ required: 'Course Objectives are required' }}  
  />
  {errors.objectives && <p className="text-red-500 text-sm mt-1">{errors.objectives.message}</p>}
</div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <Label htmlFor="course_type">Program Type</Label>
              <SelectDropdown
                name="course_type"
                control={control}
                options={optionsCoursetype}
                className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Select Program Type..."
                rules={{ required: 'Please select at least one Program type' }}
              />
              {errors.course_type && <p className="text-red-500 text-sm mt-1">{errors.course_type.message}</p>}
            </div>
            <div>
              <Label htmlFor="country_id">Country</Label>
              <MultiSelectDropdown
                name="country_id"
                control={control}
                options={countryList}
                className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Select Country..."
                rules={{ required: 'Please select at least one Country' }}
              />
              {errors.country_id && <p className="text-red-500 text-sm mt-1">{errors.country_id.message}</p>}
            </div>
            <div>
              <Label htmlFor="preview_video">Preview Video</Label>
              <input
                type="text"
                {...register('preview_video', {
                  required: 'Preview video link is required',
                  pattern: {
                    // value: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|vimeo\.com)\/.+$/,
                    message: 'Please enter a valid YouTube or Vimeo link'
                  }
                })}
                className="w-full px-4 py-2 border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Enter Preview Video Link"
              />
              {errors.preview_video && <p className="text-red-500 text-sm mt-1">{errors.preview_video.message}</p>}
            </div>
          </div>

          <div>
            <Label htmlFor="detailed_desc">Detailed Desc</Label>
            <EditorComponent
              name="detailed_desc"
              control={control}
              rules={{ required: 'Course Description is required' }}
            />
            {errors.detailed_desc && <p className="text-red-500 text-sm mt-1">{errors.detailed_desc.message}</p>}
          </div>

        

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <Label htmlFor="specialities">Specialty</Label>
              <MultiSelectDropdown
                name="specialities"
                control={control}
                options={specialities}
                className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Select specialty..."
                rules={{ required: 'Please select at least one specialty' }}
              />
              {errors.specialities && <p className="text-red-500 text-sm mt-1">{errors.specialities.message}</p>}
            </div>
            <div>
              <Label htmlFor="status">Status</Label>
              <SelectDropdown
                name="status"
                control={control}
                options={courseStatus}
                className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Select status..."
                rules={{ required: 'Please select a status' }}
              />
              {errors.status && <p className="text-red-500 text-sm mt-1">{errors.status.message}</p>}
            </div>
            <div>
              <Label htmlFor="education_partners">Education Partners</Label>
              <MultiSelectDropdown
                name="education_partners"
                control={control}
                options={educationPartners}
                className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Select education partners..."
                rules={{ required: 'Please select at least one Partner' }}
              />
              {errors.education_partners && <p className="text-red-500 text-sm mt-1">{errors.education_partners.message}</p>}
            </div>
            <div>
              <Label htmlFor="qualification">Qualification</Label>
              <MultiSelectDropdown
                name="qualification"
                control={control}
                options={qualifications}
                className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none"
                placeholder="Select Qualification..."
                rules={{ required: 'Please select at least one Qualification' }}
              />
              {errors.qualification && <p className="text-red-500 text-sm mt-1">{errors.qualification.message}</p>}
            </div>

            <div>
        <label>Start Date</label>
        <input
          type="date"
          {...register('start_date', { required: 'Start date is required' })}
          className="w-full px-4 py-2 border border-gray-200 rounded-md"
        />
        {errors.start_date && <p className="text-red-500 text-sm">{errors.start_date.message}</p>}
      </div>

      <div>
        <label>End Date</label>
        <input
          type="date"
          {...register('end_date', { required: 'End date is required' })}
          className="w-full px-4 py-2 border border-gray-200 rounded-md"
        />
        {errors.end_date && <p className="text-red-500 text-sm">{errors.end_date.message}</p>}
      </div>

      <div>
      <label htmlFor="course_brochure" className="block text-gray-700 mb-2">
        Course Brochure (PDF only)
      </label>

      {/* Hidden File Input */}
      <input
        id="course_brochure"
        type="file"
        accept=".pdf"
        onChange={handleBrochureUpload}
        className="hidden"
      />

      {/* Custom Label that acts as a button */}
      <label
        htmlFor="course_brochure"
        className="w-full px-4 py-2 border border-gray-200 rounded-md text-gray-700 cursor-pointer hover:bg-gray-100 focus:border-green-400 focus-visible:outline-none"
      >
        {fileBrochure ? 'File selected' : 'Choose a file'}
      </label>

      {/* Display the file name if available */}
      {fileBrochure && fileBrochure.url && (
        <div className="text-green-500 text-sm mt-1">
          {/* Display the file name and a link to download if it's a URL */}
          {fileBrochure.url.startsWith('data:') ? (
            <p>Uploaded: {fileBrochure.name}</p>
          ) : (
            <a href={fileBrochure.url} target="_blank" rel="noopener noreferrer">
              Uploaded: {fileBrochure.name} (Click to view)
            </a>
          )}
        </div>
      )}

 
    </div>
    </div>

          {isToggledPaid && (
  <div>
    <Label htmlFor="prices">Course Prices</Label>
    {priceInputs.map((input, index) => (
      
      <PriceInput
      key={input.id}
      control={control}
      index={index}
      isPrimary={input.is_primary}
      onRemove={removePriceInput}
      countryList={countryList}
      togglePrimary={togglePrimary} // Make sure this prop is passed correctly
    />
    
    ))}
    <button
      type="button"
      onClick={addPriceInput}
      className="mt-2 px-4 py-2 bg-[#247F4D] text-white rounded-md w-full sm:w-auto"
    >
      Add  Price
    </button>
  </div>
)}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <Label htmlFor="video_thumbnail_path">Video Thumbnail (770x550 pixels)</Label>
            <Upload
              listType="picture-card"
              fileList={fileVideoThumbnail}
              onPreview={handlePreview}
              onChange={handleChangeImage}
              beforeUpload={() => false}
            >
              {fileVideoThumbnail.length === 1 ? null : uploadButton}
            </Upload>
          </div>
          <div>
  <Label htmlFor="square_image_path">Square Image (600x600 pixels)</Label>
  <Upload
    listType="picture-card"
    fileList={fileSquareImage}
    onPreview={handlePreview}
    onChange={handleChangeSquareImage}
    beforeUpload={() => false}
  >
    {fileSquareImage.length >= 1 ? null : uploadButton}
  </Upload>
  {imageErrors.squareImage && <p className="text-red-500 text-sm mt-1">{imageErrors.squareImage}</p>} {/* Display Square Image error */}
</div>

<div>
  <Label htmlFor="course_certificate_path">Course Certificate (1600x1165 pixels, max 1 MB)</Label>
  <Upload
    listType="picture-card"
    fileList={fileCertificate}
    onPreview={handlePreview}
    onChange={handleChangeCertificate}
    beforeUpload={() => false}
  >
    {fileCertificate.length >= 1 ? null : uploadButton}
  </Upload>
  {imageErrors.courseCertificate && <p className="text-red-500 text-sm mt-1">{imageErrors.courseCertificate}</p>} {/* Display Course Certificate error */}
</div>
        
        </div>

        <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>

        <div className="mt-6 sm:justify-end flex">
          <button
            type="submit"
            className="px-6 py-2 bg-[#247F4D] text-sm text-white rounded-md hover:bg-[#1c6b3e] transition-colors w-full sm:w-auto"
          >
            Submit
          </button>
        </div>
        </div>
      </form>
      </Tabs.TabPane>

      {location.search.includes('id') && (
  <Tabs.TabPane tab="FAQ" key="2">
    <Faq courseId={courseId} onSubmitFAQ={onSubmitFAQ} />
  </Tabs.TabPane>
)}


     </Tabs>
    </div>
  );
};



export default Step1;
